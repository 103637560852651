<template>
  <modal name="timeout-message">
    <div class="rfs-m-8">
      <h2 class="modal-title">Are you still there?</h2>
      <p>
        You have been idle for
        <b class="fw-b">{{ minutesIdle }} {{ minutesIdleLabel }}</b
        >. For your security we will log you out automaticly in
        <b class="fw-b">{{ timeLeft }} {{ timeLeftLabel }}</b
        >. Click anywhere on this window to dismiss and reset timeout.
      </p>
    </div>
  </modal>
</template>

<script>
  export default {
    props: ['secondsIdle', 'timeoutSeconds'],
    data() {
      return {
      };
    },
    computed: {
      secondsLeft() {
        let sec = Math.ceil(this.timeoutSeconds - this.secondsIdle);
        return sec >= 0 ? sec : 0;
      },
      secondsLeftLabel() {
        return this.secondsLeft == 1 ? 'second' : 'seconds';
      },
      minutesIdle() {
        return Math.ceil(this.secondsIdle / 60);
      },
      minutesIdleLabel() {
        return this.minutesIdle == 1 ? 'minute' : 'minutes';
      },
      minutesLeft() {
        return Math.ceil(this.secondsLeft / 60);
      },
      minutesLeftLabel() {
        return this.minutesLeft == 1 ? 'minute' : 'minutes';
      },
      timeLeft() {
        if (this.secondsLeft <= 60) {
          return this.secondsLeft;
        } else {
          return this.minutesLeft;
        }
      },
      timeLeftLabel() {
        if (this.secondsLeft <= 60) {
          return this.secondsLeftLabel;
        } else {
          return this.minutesLeftLabel;
        }
      }
    },
    mounted() {
      // Prevents the message from flashing up when logging out
      if (this.$storage.get('idleTimeStart')) {
        this.$modal.show('timeout-message');
      }
    }
  };
</script>
